.table {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  background: $color-white;

  &--normal tr td {
    height: auto;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  &--scrolled {
    @media(max-width: $max-breakpoint-xl) {
      td, th {
        border-radius: 0 !important;
      }
    }
  }

  th {
    text-align: left;

    font-weight: bold;
    font-size: 1em;
    line-height: 1.2;

    color: $color-black;
  }

  td {
    font-weight: 500;
    font-size: 1em;
    line-height: 1.2;

    color: #565656;
  }

  tr:first-child th,
  tr:first-child td {
    border-top: 1px solid #ACACAC;
  }

  tr:last-child td {
    border-bottom: 1px solid #ACACAC;
  }

  th,td {
    padding: 0 1.5em;
    border: 1px solid #DEDEDE;
    border-left: none;
    border-top: none;
    height: 64px;
  }

  th:first-child, td:first-child {
    border-left: 1px solid #ACACAC;
  }

  th:last-child, td:last-child {
    border-right: 1px solid #ACACAC;
  }

  tr:first-child th:first-child,
  tr:first-child td:first-child {
    border-top-left-radius: 12px;
  }

  tr:first-child th:last-child,
  tr:first-child td:last-child{
    border-top-right-radius: 12px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 12px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 12px;
  }

  &__table-data {
    &--not-normal {
      margin-left: -.7em;
      padding: .25em .7em;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.25;
      color: #FF6C6C;
      background: #FBE8E8;
      border-radius: 12px;
    }
  }

  &__notice-wrapper {
    margin-top: 1.5em;

    @media(max-width: $max-breakpoint-xl) {
      display: none;
    }
  }

  &__notice {
    display: inline-block;
    margin: 0 1.714em 0 0;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.2;

    color: $color-black;

    &::before {
      content: '';
      display: inline-block;
      margin-right: 8px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      box-sizing: border-box;
      vertical-align: -.2em;
    }

    &--not-normal {
      &::before {
        background: #FF6C6C;
      }
    }

    &--normal {
      &::before {
        border: 1px solid #000000;
      }
    }
  }
}