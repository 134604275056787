.media-objects {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  &__item {
    flex: 0 0 50%;
    padding: 0 20px;
    box-sizing: border-box;

    &:nth-last-child(1) , &:nth-last-child(2) {
      .media-objects {
        &__title {
          margin-bottom: 0;
        }
      }
    }
  }

    &__title {
      display: flex;
      align-items: center;
      padding: 1.5em;
      border-radius: 12px;
      background-color: $color-white;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.2;

      color: $color-black;
      margin-top: 0;
      margin-bottom: 1em;

      &::before {
        flex: 0 0 86px;
        margin-right: 34px;
      }
    }
}