body, span, div, button, a, li, input {
  font-family: "Inter", sans-serif;
}

.text-align-center {
  text-align: center;
}

.text-page {
  p {
    margin-bottom: 2em;
  }
  ul {
    list-style-type: disc !important;
    padding-left: 2em !important;
    font-weight: bold;
  }
}

.rzd .patient-info-card__number,
.rzd .patient-info-card__description {
  display: none;
}

.rzd .patient-info-card__item:nth-child(4) {
  margin-bottom: 0;
}
.rzd .question__button {
  background: #F3F3F3;
}

.rzd .progress-line__line {
  background: rgba(#31F026, .15);
}

.rzd .question__button--active {
  background: rgba(#E3051C, .4);
  background: rgba(#2588E6, .5);
  color: white;
}

.rzd .questionnaire-groups {
  background: transparent;
  border: 1px solid rgba(#2588E6, .5);
}

.rzd .button-primary {
  background: rgba(#2588E6, .9);
  font-weight: bold;
}

.rzd .button-primary:hover {
  background: #2588E6;
}

.rzd .button-primary[disabled] {
  background: #2588E6;
  opacity: .3;
}

.rzd .question {
  border: 1px solid rgba(#2588E6, .5);
}

.rzd .header__inner--fixed-height {
  height: 80px;
}

.rzd .main-container {
  min-height: calc(100vh - 245px);
  margin-top: 80px;
}


