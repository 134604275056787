.mobile-nav {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F0F8FD;
  padding: 0 15px;
  opacity: 0;
  visibility: hidden;
  transition: .3s;

  &--opened {
    opacity: 1;
    visibility: visible;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

    &__item {
      padding: 1.5em 0;
      border-bottom: 1px solid #BDDAED;

      &:last-child {
        border-bottom: none;
      }

      &--no-padding {
        padding: 0;
        border-bottom: none;
      }
    }

      &__link {
        font-size: 1.5rem;
        font-weight: normal
      }

  &__contact {
    position: absolute;
    bottom: 84px;
    font-weight: 500;
    font-size: 1.5rem;
    color: #121416;
  }

  &__social {
    position: absolute;
    display: block;
    bottom: 36px;

    li {
      display: inline-block;
      margin-right: 24px;
    }
  }
}