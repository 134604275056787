.result-list {
  margin-top: 1em;
  flex-wrap: wrap;

  @media(max-width: $max-breakpoint-xl) {
    display: block;
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;

    @media(max-width: $max-breakpoint-xl) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__item {
    flex: 0 0 50%;
    padding: 0 20px;
    box-sizing: border-box;
    margin-top: 1.5em;

    @media(max-width: $max-breakpoint-xl) {
      padding: 0;
    }
  }

  &__item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 12px;

    @media(max-width: $max-breakpoint-xl) {
      display: block;
    }
  }

    &__info {
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media(max-width: $max-breakpoint-xl) {
        display: block;
        height: auto;
      }
    }

    &__title {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.2;

      color: #585858;

      @media(max-width: $max-breakpoint-xl) {
        margin-bottom: .666em;
      }
    }

    &__date {
      font-weight: 500;
      font-size: .875rem;
      line-height: 1.2;

      color: #B0B0B0;

      @media(max-width: $max-breakpoint-xl) {
        margin-bottom: 1.75em;
      }
    }

    &__button {
      display: inline-block;
      padding: 1.1em 2.85em;
      border: 1px solid $color-black;
      border-radius: 30px;
      font-weight: 500;
      font-size: .875rem;
      line-height: 1.2;
      text-align: center;
      color: $color-black;

      @media(max-width: $max-breakpoint-xl) {
        padding-left: 1.5em;
        padding-right: 1.5em;
        flex: 0 0 135px;
        width: 135px;
        box-sizing: border-box;
      }


      & + & {
        margin-top: 1.5em;

        @media(max-width: $max-breakpoint-xl) {
          margin: 0;
        }
      }
    }
}