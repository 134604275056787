.scale-3 {
  display: flex;
  justify-content: space-between;

  @media(max-width: $max-breakpoint-xl) {
    display: block;
  }

  &__item {
    flex: 1 1 25%;
    padding-right: 1em;
    box-sizing: border-box;

    @media(max-width: $max-breakpoint-xl) {
      margin-bottom: 1.5em;
    }
  }

  & + & {
    margin-top: 1.5em;
  }
}

.scale-3-item {
  position: relative;

  &:nth-child(1):nth-last-child(4) {
    &::before {
      background: #7AD669
    }
  }

  &:nth-child(2):nth-last-child(3) {
    &::before {
      background: #FFCA43
    }
  }

  &:nth-child(3):nth-last-child(2) {
    &::before {
      background: #FFA25A
    }
  }

  &:nth-child(4):nth-last-child(1) {
    &::before {
      background: #FF6352;
    }
  }

  &:nth-child(1):nth-last-child(4) & {
    &__value {
      color: #7AD669
    }

    &__title {
      color: #7AD669
    }
  }

  &:nth-child(2):nth-last-child(3) & {
    &__value {
      color: #FFCA43
    }

    &__title {
      color: #FFCA43
    }
  }

  &:nth-child(3):nth-last-child(2) & {
    &__value {
      color: #FFA25A
    }

    &__title {
      color: #FFA25A
    }
  }

  &:nth-child(4):nth-last-child(1) & {
    &__value {
      color: #FF6352;
    }

    &__title {
      color: #FF6352;
    }
  }

  &:nth-child(1):nth-last-child(4) {
    &::before {
      background: #7AD669
    }
  }

  &:nth-child(2):nth-last-child(3) {
    &::before {
      background: #FFCA43
    }
  }

  &:nth-child(3):nth-last-child(2) {
    &::before {
      background: #FFA25A
    }
  }

  &:nth-child(4):nth-last-child(1) {
    &::before {
      background: #FF6352;
    }
  }

  &:nth-child(1):nth-last-child(3) & {
    &__value {
      color: #FF9D66;
    }

    &__title {
      color: #FF9D66;
    }
  }

  &:nth-child(2):nth-last-child(2) & {
    &__value {
      color: #56D433;
    }

    &__title {
      color: #56D433;
    }
  }

  &:nth-child(3):nth-last-child(1) & {
    &__value {
      color: #FF6C6C;
    }

    &__title {
      color: #FF6C6C;
    }
  }

  &:nth-child(1):nth-last-child(3) {
    &::before {
      background: #FF9D66;
    }
  }

  &:nth-child(2):nth-last-child(2) {
    &::before {
      background: #56D433;
    }
  }

  &:nth-child(3):nth-last-child(1) {
    &::before {
      background: #FF6C6C;
    }
  }

  &:nth-child(1):nth-last-child(2) & {
    &__value {
      color: #56D433;
    }

    &__title {
      color: #56D433;
    }
  }

  &:nth-child(2):nth-last-child(1) & {
    &__value {
      color: #FF6C6C;
    }

    &__title {
      color: #FF6C6C;
    }
  }

  &:nth-child(1):nth-last-child(2) {
    &::before {
      background: #56D433;
    }
  }

  &:nth-child(2):nth-last-child(1) {
    &::before {
      background: #FF6C6C;
    }
  }

  &:nth-child(1):nth-last-child(5) & {
    &__value {
      color: #7AD669
    }

    &__title {
      color: #7AD669
    }
  }

  &:nth-child(2):nth-last-child(4) & {
    &__value {
      color: #FFCA43
    }

    &__title {
      color: #FFCA43
    }
  }

  &:nth-child(3):nth-last-child(3) & {
    &__value {
      color: #FFA25A
    }

    &__title {
      color: #FFA25A
    }
  }

  &:nth-child(4):nth-last-child(2) & {
    &__value {
      color: #FF6352;
    }

    &__title {
      color: #FF6352;
    }
  }

  &:nth-child(5):nth-last-child(1) & {
    &__value {
      color: #ca3636;
    }

    &__title {
      color: #ca3636;
    }
  }

  &:nth-child(1):nth-last-child(5) {
    &::before {
      background: #7AD669
    }
  }

  &:nth-child(2):nth-last-child(4) {
    &::before {
      background: #FFCA43
    }
  }

  &:nth-child(3):nth-last-child(3) {
    &::before {
      background: #FFA25A
    }
  }

  &:nth-child(4):nth-last-child(2) {
    &::before {
      background: #FF6352;
    }
  }

  &:nth-child(5):nth-last-child(1) {
    &::before {
      background: #ca3636;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    border-radius: 12px;
  }

  &__value {
    padding: .22em 0 0 .666em;
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 1.2
  }

  &__title {;
    padding: 0 0 .5em 1.333em;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.2;
  }
}