.appointment-with-a-doctor {
  margin: 4.5rem 0 0 0;
  padding: 2.25rem;
  background: #E1EFF8;
  border-radius: 12px;
  overflow: hidden;

  @media(max-width: $max-breakpoint-xl) {
    padding: 0.95rem;
  }

  &__title {
    margin: 0 2em 2em 0;
    font-weight: 500;
    font-size: 2.25em;
    line-height: 1.222;

    color: $color-black;

    @media(max-width: $max-breakpoint-xl) {
      font-size: 1.25em;
      margin-right: 0;
      margin-bottom: 1.5em;
    }
  }

  &__flex-wrapper {
    display: flex;

    @media(max-width: $max-breakpoint-xl) {
      flex-wrap: wrap;
    }
  }

  &__image {
    max-width: 360px;
    margin-right: 40px;
    border-radius: 20px;
    vertical-align: top;

    @media(max-width: $max-breakpoint-xl) {
      margin: 0;
      width: 100%;
    }
  }

  &__offer {
    margin: 0 0 .8667em 0;
    font-style: normal;
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 1.222;

    color: $color-black;

    @media(max-width: $max-breakpoint-xl) {
      font-size: 1.125rem;
      margin: 0 0 1.5em 0;
    }

    &--mobile {
      display: none;

      @media(max-width: $max-breakpoint-xl) {
        display: block;
      }
    }

    &--desktop {
      display: block;

      @media(max-width: $max-breakpoint-xl) {
        display: none;
      }
    }
  }

  &__doctor-name {
    margin: 0 0 .5em 0;
    font-weight: bold;
    font-size: 3rem;
    line-height: 1.1;

    color: $color-dark-blue;

    @media(max-width: $max-breakpoint-xl) {
      font-size: 2em;
      margin-top: 0.75em;
      margin-right: 1em;
    }
  }

  &__position {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1;

    color: #444444;

    @media(max-width: $max-breakpoint-xl) {
      font-size: 1.125rem;
      margin-bottom: 2.25em;
    }
  }

  &__left-column {
    flex-basis: 400px;
  }

  &__right-column {
    position: relative;
    flex: 1 1 auto;
  }

  &__button {
    position: absolute;
    bottom: 0;
    left: 0;

    @media(max-width: $max-breakpoint-xl) {
      position: static;
      display: block;
    }
  }
}