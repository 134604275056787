.container {
  padding: 0 15px;
  margin: 0 auto;

  @media(min-width: $min-breakpoint-xl) {
    padding: 0 20px;
    width: $container-xl;
  }
}

.row {
  @media(min-width: $min-breakpoint-xl) {
    display: flex;
    margin: 0 -20px;
  }
}

.col {
  flex: 1 0 0;
  padding: 0 20px;
  box-sizing: border-box;

  @media(max-width: $max-breakpoint-xl) {
    padding: 0;
  }
}

.hidden-xs {
  @media(max-width: $max-breakpoint-xl) {
    display: none !important;
  }
}

.hidden-xl {
  @media(min-width: $min-breakpoint-xl) {
    display: none !important;
  }
}