.questionnaire-card {
  display: block;
  position: relative;
  border-radius: .75em;
  text-decoration: none;
  background: linear-gradient(90deg, #CEEDFF 0%, #BBD6FF 100%);
  padding: 2em;

  @media(max-width: $max-breakpoint-xl) {
    padding: 1.5em;
    min-height: 278px;
    box-sizing: border-box;
  }

  &::before {
    width: 64px;
    height: 64px;
  }

  &--main {
    background: linear-gradient(90deg, #FFCFC5 0%, #FFAFAF 100%);
    padding: 3em;

    @media(max-width: $max-breakpoint-xl) {
      padding: 1.625em;
    }

    &::before {
      width: 102px;
      height: 102px;
    }
  }

  &--main & {
    &__info-link {
      right: 3em;
      background: #FF9B9A url("/static/media/info-icon-red.svg") center no-repeat;

      @media(max-width: $max-breakpoint-xl) {
        right: 1.625em;
        top: 1.625em;
      }
    }

    &__heading {
      @media(max-width: $max-breakpoint-xl) {
        position: static;
      }
    }

    &__notice {
      @media(max-width: $max-breakpoint-xl) {
        margin-bottom: 3.1666em;
      }
    }
  }

  &--with-button {
    display: flex;
    flex-wrap: wrap;

    &::before {
      margin-right: 2.25em;
    }

    @media(max-width: $max-breakpoint-xl) {
      display: block;
      padding: 1.5em;
    }
  }

  &--with-button & {
    &__button-wrapper {
      flex: 1 1 100%;
      width: 100%;
      margin-top: 1.75rem;
    }

    &__heading {
      margin-top: 0;
      max-width: 270px;

      @media(max-width: $max-breakpoint-xl) {
        display: flex;
        align-items: flex-end;
        height: 2.67em;
        margin-top: .666em;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.2
      }
    }

    &__notice {
      font-weight: 700;
      font-size: .875rem;

      @media(max-width: $max-breakpoint-xl) {
        margin: 1em 0;
        font-weight: 500;
        font-size: 1.125rem;
      }
    }

    &__button-wrapper {
      @media(max-width: $max-breakpoint-xl) {
        margin-top: 1.5em;
      }
    }

    &__button {
      background-color: #3B7FE1;

      @media(max-width: $max-breakpoint-xl) {
        width: 100%;
        padding: 1em 0;
        font-size: 1.125rem;
      }
    }
  }

  &--with-button#{&}--main & {
    &__heading {
      max-width: 522px;

      @media(max-width: $max-breakpoint-xl) {
        height: auto;
      }
    }

    &__button {
      background-color: #FF7A70;
    }
  }

  &__heading {
    margin: .9em 0 0 0;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.2;

    color: $color-black;

    @media(max-width: $max-breakpoint-xl) {
      /*position: absolute;
      left: 1.083em;
      bottom: 1.083em;
      right: 1.083em;*/
      margin: .8em 0 0 0;
    }
  }

  &__notice {
    margin: 0.5em 0 0 0;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 1.2;

    color: $color-black;

    @media(max-width: $max-breakpoint-xl) {
      margin: 1.333em 0 0 0;
    }
  }

  &__info-link {
    top: 2.25em;
    right: 2.25em;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #AACAF2 url("/static/media/info-icon-blue.svg") center no-repeat;
    cursor: pointer;

    @media(max-width: $max-breakpoint-xl) {
      right: 1.625em;
      top: 1.625em;
    }

    &:hover + .questionnaire-card__info, &:focus + .questionnaire-card__info {
      opacity: 1;
      visibility: visible;
    }
  }

  &__info {
    position: absolute;
    top: 4rem;
    right: 1.2rem;
    margin: 0;
    max-width: 253px;
    padding: 1.14em;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 40px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.2;
    color: #444444;
    background-color: $color-white;
    z-index: 1;

    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }

  &--secondary {
    background: #E1EFF8;
    flex-wrap: nowrap;

    &::before {
      display: block;
      flex: 0 0 64px;
    }
  }

  &--secondary & {
    &__heading {
      max-width: 100%;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.2;
      color: $color-black;
    }

    &__text {
      margin-top: .4em;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.2;
      color: $color-black;
    }

    &__info-link {
      background-color: #C7DDEB;
    }

    &__button-wrapper {
      margin-top: 1.5em;
    }

    &__button {
      background: $color-black;
    }
  }

  &--white {
    background-color: $color-white;
  }

  &--white & {
    &__info-link {
      background-color: #EEEEEE;
    }
  }
}