[class*="icon-"] {
  &::before {
    content: '';
    display: inline-block;
    background: center no-repeat;
  }
}

.icon-depression {
  &::before {
    width: 61px;
    height: 52px;
    background-image: url('/static/media/depression-icon.svg');
  }
}

.icon-diabetes {
  &::before {
    width: 64px;
    height: 62px;
    background-image: url('/static/media/diabetes-icon.svg');
  }
}

.icon-discussion {
  &::before {
    width: 154px;
    height: 64px;
    background-image: url('/static/media/discussion-icon.svg');

    @media(max-width: $max-breakpoint-xl) {
      width: 169px;
      height: 78px;
      background-image: url('/static/media/discussion-xs-icon.svg');
    }
  }
}

.icon-exclamation {
  &::before {
    width: 18px;
    height: 100px;
    background-image: url('/static/media/exclamation-icon.svg');
  }
}

.icon-facebook {
  &::before {
    width: 32px;
    height: 32px;
    background-image: url('/static/media/facebook-icon.svg');
  }
}

.icon-facebook-2 {
  &::before {
    width: 24px;
    height: 24px;
    background-image: url('/static/media/facebook-2-icon.svg');
  }
}

.icon-heart {
  &::before {
    width: 102px;
    height: 102px;
    background-image: url('/static/media/heart-icon.svg');
  }
}

.icon-heart-2 {
  &::before {
    width: 64px;
    height: 64px;
    background-image: url('/static/media/heart-3-icon.svg');
  }
}

.icon-instagram {
  &::before {
    width: 32px;
    height: 32px;
    background-image: url('/static/media/instagram-icon.svg');
  }
}

.icon-instagram-2 {
  &::before {
    width: 24px;
    height: 24px;
    background-image: url('/static/media/instagram-2-icon.svg');
  }
}

.icon-lungs {
  &::before {
    width: 64px;
    height: 51px;
    background-image: url('/static/media/lungs-icon.svg');
  }
}

.icon-email {
  &::before {
    width: 32px;
    height: 32px;
    background-image: url('/static/media/mail-icon.svg');
  }
}

.icon-plus {
  &::before {
    width: 24px;
    height: 24px;
    background-image: url('/static/media/plus-icon.svg');
  }
}

.icon-reflux {
  &::before {
    width: 64px;
    height: 66px;
    background-image: url('/static/media/reflux-icon.svg');
  }
}

.icon-result {
  &::before {
    width: 162px;
    height: 64px;
    background-image: url('/static/media/result-icon.svg');

    @media(max-width: $max-breakpoint-xl) {
      width: 169px;
      height: 67px;
      background-image: url('/static/media/result-xs-icon.svg');
    }
  }
}

.icon-screening {
  &::before {
    width: 155px;
    height: 64px;
    background-image: url('/static/media/screening-icon.svg');

    @media(max-width: $max-breakpoint-xl) {
      width: 169px;
      height: 66px;
      background-image: url('/static/media/screening-xs-icon.svg');
    }
  }
}

.icon-telegram {
  &::before {
    width: 32px;
    height: 28px;
    background-image: url('/static/media/telegram-icon.svg');
  }
}

.icon-vk {
  &::before {
    width: 24px;
    height: 14px;
    background-image: url('/static/media/vk-icon.svg');
  }
}

.icon-vk-2 {
  &::before {
    width: 64px;
    height: 64px;
    background-image: url('/static/media/vk-icon.svg');
    background-size: 100%;
  }
}

.icon-depression-man {
  &::before {
    width: 64px;
    height: 64px;
    background-image: url('/static/media/depression-man-icon.svg');
  }
}

.icon-pills {
  &::before {
    width: 64px;
    height: 64px;
    background-image: url('/static/media/pills-icon.svg');
  }
}

.icon-anxiety {
  &::before {
    width: 64px;
    height: 64px;
    background-image: url('/static/media/anxienty-icon.svg');
  }
}

.icon-hobl-1 {
  &::before {
    width: 64px;
    height: 64px;
    background-image: url('/static/media/hobl-1-icon.svg');
  }
}

.icon-hobl-2 {
  &::before {
    width: 64px;
    height: 64px;
    background-image: url('/static/media/hobl-2-icon.svg');
  }
}

.icon-hobl-3 {
  &::before {
    width: 64px;
    height: 64px;
    background-image: url('/static/media/hobl-3-icon.svg');
  }
}

.icon-pills-2 {
  &::before {
    width: 86px;
    height: 86px;
    background-image: url('/static/media/pills-2-icon.svg');
  }
}

.icon-psychotherapy {
  &::before {
    width: 86px;
    height: 86px;
    background-image: url('/static/media/psychotherapy-icon.svg');
  }
}

.icon-ball {
  &::before {
    width: 86px;
    height: 86px;
    background-image: url('/static/media/ball-icon.svg');
  }
}

.icon-positive-man {
  &::before {
    width: 86px;
    height: 86px;
    background-image: url('/static/media/positive-man-icon.svg');
  }
}

[class^="food-"] + [class^="food-"] {
  margin-top: 16px;
}

@each $icon in ("bread", "potato", "buckwheat", "chicken", "fish", "meat", "eggs", "milk", "vegetables", "fruits", "oil", "nuts", "cookies", "chocolate") {
  .food-#{$icon} {
    content: url("/static/media/#{$icon}-table-icon.png");
  }
}