.result-section {
  &__heading {
    margin-top: 0;
    padding-top: 2em;
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 1.5;
    color: $color-black;

    @media(max-width: $max-breakpoint-xl) {
      font-size: 1.5rem;
      line-height: 1.222;
    }

    &--no-padding-bottom {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &--no-padding-top {
      padding-top: 1.3em;

      @media(max-width: $max-breakpoint-xl) {
        padding-top: 0;
      }
    }

    &--normal-padding-top {
      padding-top: 1.55em;
    }

    &--add-margin-top {
      margin-bottom: 1em;
    }

    &--mini {
      padding-top: 1.5em;
      font-size: 1.5rem;
      font-weight: 700;
    }

    &--mobile {
      margin-top: 1.5em;
    }
  }

  &__scale {
    margin-top: 2.75em;

    @media(max-width: $max-breakpoint-xl) {
      margin-top: 1.5em;
    }
  }

  &__main-result {
    padding: 0;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 1.5;
    color: $color-black;

    @media(max-width: $max-breakpoint-xl) {
      margin-top: .666em;
      font-size: 1.5rem;
    }
  }

  &__description {
    overflow: hidden;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.5;

    color: #767676;

    p {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}