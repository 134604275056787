.cookies-panel {
  padding: 1.5em 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #CEEDFF 0%, #BBD6FF 100%);
  text-align: left;

  @media(max-width: $max-breakpoint-xl) {
    padding: 2.25em 0;
  }

  &__flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: $max-breakpoint-xl) {
      display: block;
    }
  }

  &__heading {
    margin: 0;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.3;

    color: $color-black;
  }

  &__description {
    margin: .22em 0 0 0;
    font-size: 1.125rem;
    line-height: 1.2;

    color: #636363;

    @media(max-width: $max-breakpoint-xl) {
      font-size: .875rem;
      margin-bottom: 1.125em;
    }
  }

  &__button {
    background-color: #3B7FE1;
    min-width: 180px;
  }
}