.main-container {
  min-height: calc(100vh - 225px);
  margin-top: 100px;
  padding-bottom: 4.5em;
  box-sizing: border-box;
  background-color:  #FBFBFB;

  @media(max-width: $max-breakpoint-xl) {
    min-height: calc(100vh - 243px);
    margin-top: 70px;
    padding-bottom: 3em;
  }

  &--no-header {
    margin-top: 0;
    min-height: calc(100vh - 125px);
  }

  &__header {
    flex: 1 1 60%;
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 1.2;
    margin: 0;

    &--no-padding-bottom {
      padding-bottom: 0;
    }

    @media(max-width: $max-breakpoint-xl) {
      padding-top: 2em;
      font-weight: bold;
      font-size: 1.5rem;

      color: $color-black;
    }
  }

  &__description {
    line-height: 1.333;
    font-size: 1.125rem;
    color: #575757;
    padding-bottom: 2.111em;

    @media(max-width: $max-breakpoint-xl) {
      padding-bottom: 1.125em;
    }

    &--no-padding-bottom {
      padding-bottom: 1em;

      @media(max-width: $max-breakpoint-xl) {
        padding-bottom: 0;
      }
    }

    ul {
      padding-left: 0;
      list-style-type: none;
    }
  }
}
