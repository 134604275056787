.heading {
  margin: 0;
  padding-top: 2em;
  padding-bottom: .75em;
  font-weight: bold;
  font-size: 3rem;
  line-height: 1.20;

  color: $color-black;

  @media(max-width: $max-breakpoint-xl) {
    padding-top: 1.3333em;
    padding-bottom: .666em;
    font-size: 2.25rem;
  }

  &--sub {
    padding-top: 1.33em;
    padding-bottom: 1em;
    font-size: 2.25rem;
  }

  &--with-plus {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -38px;
      top: 57.3%;
      width: 24px;
      height: 24px;
      background-image: url('/static/media/plus-icon.svg');
    }
  }
}